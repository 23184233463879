import React from "react"
// import firebase from "gatsby-plugin-firebase"
import "../styles/index.scss";


function RecommendationsBAPage() {

  // const [pdf, setPdf] = React.useState()


  // useFirebase(firebase => {
  //   firebase
  //     .storage()
  //     .ref()
  //     .child('images/space.jpg')
  //     .then(snapshot => {
  //       console.log(snapshot);
  //       setPdf(snapshot.val());
  //     })
  // }, [])
  

  return (
    <iframe
      src="https://firebasestorage.googleapis.com/v0/b/sommer-consulting.appspot.com/o/BA%20-%20Preparation%20materials%20-%20SOMMER.pdf?alt=media&token=19a164fc-f0ff-48e3-874c-68de983de4f3"
      style={{width: "100%", height: "100%"}}
    ></iframe>
  )

}

export default RecommendationsBAPage